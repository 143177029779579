import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "./Header.css";

function Header() {
  let links = [
    { name: "Home", link: "/" },
    { name: "About Us", link: "/about-us" },
    { name: "Contact Us", link: "/contact-us" },
    { name: "Blogs", link: "/blogs" },
  ];
  const [open, setIsOpen] = useState(false);
  return (
    <div className="shadow-md w-full fixed z-50 text-white top-0 left-0">
      <div
        className="md:flex md:px-32 items-center justify-between bg-[#004d57] opacity-85 py-2  px-7"
        onClick={() => setIsOpen(!open)}
      >
        {/* logo section */}
        <div className="font-bold text-2xl cursor-pointer flex items-center gap-1">
          <span className=" text-3xl">Dellace</span>
        </div>
        {/* Menu icon */}
        <div
          onClick={() => setIsOpen(!open)}
          className="absolute right-8 top-6 cursor-pointer md:hidden w-7 h-7"
        >
          {open ? (
            <FaAngleUp className="text-2xl" />
          ) : (
            <FaAngleDown className="text-2xl" />
          )}
        </div>
        {/* linke items */}
        <ul
          className={`md:flex md:items-center bg-[#004d57] opacity-85  md:pb-0 pb-12 absolute md:static  md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
            open ? "top-12" : "top-[-490px]"
          }`}
        >
          {links.map((link) => (
            <li
              className="md:ml-8 md:my-0 my-7 font-semibold "
              onClick={() => setIsOpen(!open)}
              key={link.link}
            >
              <NavLink
                to={link.link}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
              >
                {link.name}
              </NavLink>
              {/* <a
                href={link.link}
                className=" hover:text-red-500 text-white duration-500"
              >
                {link.name}
              </a> */}
            </li>
          ))}
          {/* <button className="btn bg-blue-600 text-white md:ml-8 font-semibold px-3 py-1 rounded duration-500 md:static">
            Get Started
          </button> */}
        </ul>
        {/* button */}
      </div>
    </div>
  );
}

export default Header;
