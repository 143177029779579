import { Route, Routes } from "react-router";
import Contactus from "./pages/Contactus";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Header from "./components/Header";
import About from "./pages/About";
import Blogs from "./pages/Blogs";
// import ReactGA from "react-ga";
import { useEffect } from "react";
import "./App.css";

// const TRACKING_ID = "G-NFDEPLGDGK"; // OUR_TRACKING_ID

import ReactGA4 from "react-ga4";
import Blogs1 from "./blogs/Blogs1";
import Buttons from "./components/Buttons";
import Blog2 from "./blogs/Blog2";
import Blog3 from "./blogs/Blog3";

const trackingId4 = "G-PWMK7V9M5M";
ReactGA4.initialize(trackingId4);

ReactGA4.send({
  hitType: "pageview",
  page: window.location.pathname,
  title: "Home",
});

// ReactGA.initialize(TRACKING_ID);
export default function App() {
  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);
  console.log("pathname", window.location.pathname);
  useEffect(() => {
    ReactGA4.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Home",
    });
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div className="font-dellace select-none">
      <Header />
      <Buttons />
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route
            path="/blog/Live-Videos-For-Your-Business-The-Ultimate-Guide"
            element={<Blogs1 />}
          />
          <Route
            path="/blog/15-Powerful-Animated-Commercials"
            element={<Blog2 />}
          />

          <Route
            path="/blog/8 Types of Videos That Help Businesses Grow in 2024"
            element={<Blog3 />}
          />
        </Route>
      </Routes>
      <Footer />
    </div>
  );
}
