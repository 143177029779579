import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { Fade, Zoom, Slide } from "react-awesome-reveal";

function Hero() {
  const sentences = [
    "Delivering brand messages to the customer",
    "Unforgettable display video advertisement",
    "Convert complex message into simple visual",
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % sentences.length);
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    // "service_o23o6jb", "template_uremfya", form.current, "UvZT-t9hwuk760JPm";
    // "service_qsptl6n", "template_ir6450n", form.current, "9AfN8eK5YEzdBYvqo";
    emailjs
      .sendForm(
        "service_o23o6jb",
        "template_03ax5cn",
        form.current,
        "UvZT-t9hwuk760JPm"
      )
      .then(
        (result) => {
          console.log(result.text);
          if (result.status == 200) {
            alert("Thank you! We'll be in touch shortly.");
            console.log("test");
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  const [videoObjective, setVideoObjective] = useState("");

  return (
    <div>
      <div
        style={{
          background:
            "linear-gradient( to right, rgb(0,77,87,0.9),rgb(115,67,177,0.9)), url('https://dellace.s3.ap-south-1.amazonaws.com/bg-img-min.png') center/cover no-repeat",
        }}
        className="h-screen  flex items-end justify-center  text-white"
      >
        <div className="text-center w-full h-[90%]  max-h-[600px] flex flex-col justify-between  items-center overflow-hidden">
          <div className=" h-full flex flex-col justify-evenly">
            <div className="mt-10">
              <Zoom>
                <div className="flex flex-col lg:flex-row lg:gap-2">
                  <h1 className="text-2xl font-bold mb-2 xl:text-5xl lg:text-4xl md:text-4xl sm:text-3xl">
                    Animated Explainer Videos
                  </h1>
                  <h1 className="text-2xl font-bold mb-2 xl:text-5xl  lg:text-4xl md:text-4xl sm:text-3xl">
                    That Tell Great Stories
                  </h1>
                </div>
              </Zoom>
            </div>
            <div className="animated-text-container">
              {sentences.map((sentence, index) => (
                <p
                  key={index}
                  className={`text-[1rem] tracking-tight whitespace-nowrap xl:text-3xl lg:text-2xl md:text-xl sm:text-lg text-white mb-6 py-1
 animated-text ${index === currentIndex ? "animate-pulse1" : "hidden"}`}
                >
                  {sentence}
                </p>
              ))}
            </div>
          </div>

          <div className=" h-fit ">
            <form ref={form} onSubmit={sendEmail} className="pb-16 ">
              <div className="flex flex-col items-center">
                <input
                  type="text"
                  name="mobile"
                  placeholder="Your mobile number"
                  className=" w-80 sm:w-96 max-w-96 hover:border-red-500 placeholder-zinc-600  px-4 py-1 m-4 md:mb-0 bg-white border text-zinc-500 border-gray-700 rounded-lg"
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Your email address"
                  className=" w-80 sm:w-96 max-w-96 hover:border-red-500 placeholder-zinc-600  px-4 py-1 m-4 md:mb-0 bg-white border text-zinc-500 border-gray-700 rounded-lg"
                />
                <select
                  className="w-80 sm:w-96 max-w-96 hover:border-red-500  px-4 py-1 m-4 md:mb-0 bg-white border text-zinc-600 border-gray-700 rounded-lg"
                  name="videoObjective"
                  value={videoObjective}
                  onChange={(e) => setVideoObjective(e.target.value)}
                >
                  <option value="">Video Objective</option>
                  <option value="Explainer video">Explainer video</option>
                  <option value="Instagram reels">Instagram reels</option>
                  <option value="Youtube ads">Youtube ads</option>
                  <option value="Product video">Product video</option>
                  <option value="Other">Other</option>
                </select>
                <button className="bg-[#ff0808] hover:bg-red-600  mt-4 py-1 text-white px-8  rounded-full">
                  Get Price
                </button>
                <p className="italic text-white py-2 ">
                  Let’s discuss what we can do for you!
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
