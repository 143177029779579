import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

function Contactus() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // "service_o23o6jb", "template_uremfya", form.current, "UvZT-t9hwuk760JPm";
    // "service_qsptl6n", "template_ir6450n", form.current, "9AfN8eK5YEzdBYvqo";

    emailjs
      .sendForm(
        "service_o23o6jb",
        "template_uremfya",
        form.current,
        "UvZT-t9hwuk760JPm"
      )
      .then(
        (result) => {
          console.log(result.text);
          if (result.status == 200) {
            alert("Request sent!!");
            console.log("test");
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div className="min-h-screen bg-[#00707C] ">
      <div
        className="min-h-[40vh] flex flex-col justify-end items-center"
        style={{
          textShadow: "2px 2px 2px black",
          background:
            "linear-gradient( to right, rgb(0,0,0,0.3),rgb(0,0,0,0.3)), url('https://dellace.s3.ap-south-1.amazonaws.com/img.jpg') center/cover no-repeat",
        }}
      >
        <p className="text-white font-extrabold tracking-wide text-5xl mb-[10vh]">
          Contact us
        </p>
      </div>
      <div className="flex flex-col md:flex-row py-20 md:pt-0">
        <form
          className="flex flex-1 flex-col justify-center   items-center md:min-h-screen px-4 "
          ref={form}
          onSubmit={sendEmail}
        >
          <input
            name="email"
            type="text"
            placeholder="Email"
            className="w-full sm:w-96  max-w-md hover:border-red-500 px-4 py-2 m-4 mb-0 bg-zinc-200 border text-black  border-gray-700 rounded"
          />
          <input
            name="mobile"
            type="text"
            placeholder="Phone Number"
            className="w-full sm:w-96 max-w-md hover:border-red-500 px-4 py-2 m-4 mb-0 bg-zinc-200 border text-black  border-gray-700 rounded"
          />
          <input
            type="text"
            name="purpose"
            placeholder="purpose"
            className="w-full sm:w-96 max-w-md hover:border-red-500 px-4 py-2 m-4 mb-0 bg-zinc-200 border text-black  border-gray-700 rounded"
          />
          <input
            type="text"
            name="details"
            placeholder="details"
            className="w-full sm:w-96 max-w-md hover:border-red-500 px-4 py-2 m-4 mb-0 bg-zinc-200 border text-black  border-gray-700 rounded"
          />

          <textarea
            name="message"
            placeholder="text"
            className="w-full sm:w-96 max-w-md hover:border-red-500 px-4 py-2 m-4 mb-0 bg-zinc-200 border text-black  border-gray-700 rounded"
          />
          <input
            type="submit"
            value="Send"
            className="bg-red-500 hover:bg-red-600  mt-10 py-1 text-white px-8  rounded-lg"
          />
        </form>
      </div>
    </div>
  );
}

export default Contactus;
