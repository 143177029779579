import React from "react";
import VideoContainer from "../components/VideoContainer";
import Pricing from "../components/Pricing";
import Messages from "../components/Messages";
import Hero from "../components/Hero";

function Home() {
  return (
    <div className="">
      <Hero />
      <VideoContainer />
      <Pricing />
      <Messages />
    </div>
  );
}

export default Home;
