import React, { useState, useRef } from "react";

import emailjs from "@emailjs/browser";
function Pricing() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    // "service_o23o6jb", "template_uremfya", form.current, "UvZT-t9hwuk760JPm";
    // "service_qsptl6n", "template_ir6450n", form.current, "9AfN8eK5YEzdBYvqo"; suraj

    emailjs
      .sendForm(
        "service_o23o6jb",
        "template_03ax5cn",
        form.current,
        "UvZT-t9hwuk760JPm"
      )
      .then(
        (result) => {
          console.log(result);
          if (result.status == 200) {
            alert("Thank you! We'll be in touch shortly.");
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  const [videoObjective, setVideoObjective] = useState("");
  return (
    <div className="text-center">
      <div>
        <h1 className="text-2xl font-bold py-8 px-4 md:text-4xl text-[#00707C]">
          Let us help you tell great stories through videos!
        </h1>
      </div>
      <form ref={form} onSubmit={sendEmail} className="flex flex-col mt-4 pb-4">
        <div className="flex flex-col p-4 items-center">
          <input
            type="text"
            name="mobile"
            placeholder="Your mobile number"
            className="w-full md:w-1/3  hover:border-red-500  px-4 py-2 m-4 placeholder-white md:mb-0 bg-[#505050] border text-white border-gray-700 rounded-lg"
          />
          <input
            type="email"
            name="email"
            placeholder="Your email address"
            className="w-full md:w-1/3  hover:border-red-500  px-4 py-2 m-4 placeholder-white md:mb-0 bg-[#505050] border text-white border-gray-700 rounded-lg"
          />
          <select
            className="w-full md:w-1/3  hover:border-red-500 px-4 py-2 m-4 md:mb-0 bg-[#505050] border text-white border-gray-700 rounded-lg"
            name="videoObjective"
            value={videoObjective}
            onChange={(e) => setVideoObjective(e.target.value)}
          >
            <option value="">Video Objective</option>
            <option value="Explainer video">Explainer video</option>
            <option value="Instagram reels">Instagram reels</option>
            <option value="Youtube ads">Youtube ads</option>
            <option value="Product video">Product video</option>
            <option value="Other">Other</option>
          </select>

          <button className="bg-[#ff0808]  hover:bg-red-600  mt-10 py-2 text-white px-8 w-64 rounded-full">
            Get Price
          </button>
        </div>
      </form>
      <div className="italic mb-4">
        Click for free consultation over the Whatsapp
      </div>
    </div>
  );
}

export default Pricing;
