import React from "react";

function About() {
  return (
    <div className="bg-[#00707C]  text-white">
      <div
        className="min-h-[40vh] flex flex-col justify-end items-center"
        style={{
          textShadow: "2px 2px 2px black",
          background:
            "linear-gradient( to right, rgb(0,0,0,0.3),rgb(0,0,0,0.3)), url('https://dellace.s3.ap-south-1.amazonaws.com/img.jpg') center/cover no-repeat",
        }}
      >
        <p className="text-white font-extrabold tracking-wide text-5xl mb-[10vh]">
          About
        </p>
      </div>
      <div className="md:flex md:p-16">
        <div className="min-h-screen lg:min-h-fit  p-4 md:pr-8">
          <h1 className="text-3xl md:text-4xl font-bold py-8">About Us</h1>
          <hr></hr>
          <p className="text-xl md:2xl pb-6 tracking-wider text-justify leading-8">
            At Dellace, we're all about making businesses awesome. Our team of
            experts creates fun and engaging creative videos and content that
            spark curiosity and are easy to understand. Our aim is to help you
            engage better and let your audience remember what they’ve seen.
          </p>
          <p className="text-xl md:2xl pb-6 tracking-wider text-justify leading-8">
            We're on a mission to create advertising unforgettable for audience,
            and we want to be a part of your success. We use innovation and
            creativity to make creatives more exciting and connected. Together,
            we are making products better for everyone!
          </p>
        </div>

        <div className="min-h-screen p-4 lg:min-h-fit md:pl-8">
          <h1 className="text-3xl md:text-4xl font-bold py-8">Vision</h1>
          <hr></hr>
          <p className="text-xl  md:2xl pb-6 tracking-wider text-justify leading-8">
            Our vision is to redefine the future of advertising, setting new
            standards of excellence globally. We are not just an advertising
            agency; we are the pioneers of user-centric content creation,
            leading the way in the ever-evolving world of marketing.
          </p>
          <p className="text-xl md:2xl pb-6 tracking-wider text-justify leading-8">
            We are expanding our horizons beyond India and reaching out to
            overseas markets in London.
          </p>
          <p className="text-xl md:2xl pb-6 tracking-wider text-justify leading-8">
            In every project, we prioritize the user experience, using
            data-driven insights to craft advertising campaigns that resonate
            deeply with audiences. Our vision is based on providing value,
            ensuring the success of our clients, and the satisfaction of our
            users.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
