import React from "react";
import BlogCard from "../components/BlogCard";

function Blogs() {
  return (
    <>
      <div
        className="min-h-[40vh] flex flex-col justify-end items-center"
        style={{
          textShadow: "2px 2px 2px black",
          background:
            "linear-gradient( to right, rgb(0,0,0,0.3),rgb(0,0,0,0.3)), url('https://dellace.s3.ap-south-1.amazonaws.com/img.jpg') center/cover no-repeat",
        }}
      >
        <p className="text-white font-extrabold tracking-wide text-5xl mb-[10vh]">
          Blogs
        </p>
      </div>
      {/* blog cards */}
      <div className="flex bg-white flex-col items-center p-4">
        <div className="">
          <div className="flex flex-wrap justify-between gap-8 py-5 ">
            {/* 1 */}
            <BlogCard
              img={
                "https://dellace.s3.ap-south-1.amazonaws.com/Instagram-blog.jpg"
              }
              title={"Live Videos For Your Business - The Ultimate Guide"}
              text={
                "video marketing strategy, video marketing examples, marketing videos for business, video marketing, business video marketing"
              }
              url={"Live-Videos-For-Your-Business-The-Ultimate-Guide"}
            ></BlogCard>

            <BlogCard
              img={
                "https://dellace.s3.ap-south-1.amazonaws.com/asia-pacific-video-streaming-market.png"
              }
              title={"15 Powerful Animated Commercials (& How They Nailed It)"}
              text={
                "animation advertising, benefits of animated ads, Animated ads"
              }
              url={"15-Powerful-Animated-Commercials"}
            ></BlogCard>

            <BlogCard
              img={
                "https://dellace.s3.ap-south-1.amazonaws.com/video+marketing.jpeg"
              }
              title={"8 Types of Videos That Help Businesses Grow in 2024"}
              text={
                "marketing campaigns, businesses planning, video marketing statistics, video ideas"
              }
              url={"8 Types of Videos That Help Businesses Grow in 2024"}
            ></BlogCard>
          </div>
        </div>
      </div>
      \
    </>
  );
}

export default Blogs;
