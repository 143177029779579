import React from "react";

import {
  FaInstagram,
  FaYoutube,
  FaLinkedinIn,
  FaFacebook,
  FaWhatsapp,
  FaPhoneVolume,
  FaEnvelope,
} from "react-icons/fa";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="flex md:flex-row md:items-start md:text-left select-text flex-col items-center text-center justify-around text-xl pt-10 text-white bg-[#004d57]  ">
      <div className="">
        <img
          src="https://dellace.s3.ap-south-1.amazonaws.com/Logo1.png"
          className="w-52"
          alt="dellace logo"
        />
      </div>

      <div>
        <div className="flex flex-col ">
          <div className="font-bold mb-6">Quick Links</div>
          <Link to={"/"} className="cursor-pointer  mb-4 hover:underline">
            Home
          </Link>
          <Link
            to={"/about-us"}
            className="cursor-pointer  mb-4 hover:underline"
          >
            About
          </Link>
          <Link
            to={"/contact-us"}
            className="cursor-pointer  mb-4 hover:underline"
          >
            Contact Us
          </Link>
          <Link to={"/blogs"} className="cursor-pointer  mb-4 hover:underline">
            Blogs
          </Link>
        </div>
      </div>

      <div className="text-center flex flex-col items-center md:items-start">
        <div className="mb-6 font-bold mt-8 md:mt-0">Social Media links</div>

        <div className="mb-4">
          <a
            href={"https://www.facebook.com/DellaceTechnologies/"}
            className="cursor-pointer hover:underline flex gap-4 "
          >
            <FaFacebook className="inline" /> Facebook
          </a>
        </div>

        <div className="mb-4">
          <a
            href={"https://www.instagram.com/dellace_official/"}
            className="cursor-pointer hover:underline flex gap-4  "
          >
            <FaInstagram className="inline" />
            Instagram
          </a>
        </div>

        <div className="mb-4">
          <a
            href={"https://www.linkedin.com/company/dellace/"}
            className="cursor-pointer hover:underline flex gap-4  "
          >
            <FaLinkedinIn className="inline-block" />
            LinkedIn
          </a>
        </div>

        <div className="mb-4">
          <a
            href={"https://www.youtube.com/channel/UCYQR6tyHbC08FJ_dRGFUi9w"}
            className="cursor-pointer hover:underline flex gap-4  mb-4"
          >
            <FaYoutube className="inline-flex" /> Youtube
          </a>
        </div>
      </div>

      <div className="md:text-right">
        <div className="mb-6 font-bold mt-8 md:mt-0 ">Corporate Address:</div>
        <div>
          <p>G-49,50, 3rd Floor, office no. 2,</p>
          <p>Sector - 3, Noida, UP - 201301</p>
        </div>
        <div className="mt-4">
          <a href="info.themepainters@gmail.com" className="hover:underline">
            <FaEnvelope className="inline" /> info.themepainters@gmail.com
          </a>
        </div>
        <div>
          <a href="tel:+919911302024" className="hover:underline">
            <FaPhoneVolume className="inline" /> +919911302024
          </a>
        </div>

        <div>
          <a
            className="whitespace-nowrap hover:underline"
            href="https://wa.me/919911302024?text=Hello"
          >
            <FaWhatsapp className="inline" /> +919911302024
          </a>
        </div>
      </div>
    </div>
  );
}
export default Footer;
