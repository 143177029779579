import React from "react";

function Messages() {
  return (
    <div className="">
      <div>
        <div className="bg-[#00707C] text-white  p-4">
          <h1 className="text-2xl md:text-3xl font-bold pt-8  text-center tracking-wide">
            Are you leveraging the power of storytelling in explainer videos?
          </h1>
          <p className=" md:text-2xl text-justify px-2 md:px-16 py-8 tracking-wider">
            Animated explainer videos are the most powerful marketing medium
            today. Yet most of them fail to garner the expected results. Why do
            you think that is? What are marketers missing? Do you wish you could
            give your clients goosebumps? Engage them emotionally and
            communicate convincingly. Leaving them charged up to take action?
            What you need is powerful storytelling. Facts do not win hearts.
            Stories do. The stakes are high, and a partnership with Explainer
            Video Agency that can help you seize a higher ROI outweighs
            everything else
          </p>
        </div>
      </div>
    </div>
  );
}

export default Messages;
