import React, { useRef, useEffect, useState } from "react";
import { Slide } from "react-awesome-reveal";

function VideoContainer(props) {
  const videoRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Adjust this threshold based on your preference
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.play().catch((error) => {
            // Handle the autoplay error here
            console.error("Autoplay failed:", error);
          });
        } else {
          entry.target.pause();
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    videoRefs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [videoRefs]);

  return (
    <div className=" overflow-hidden">
      {/* 1 */}

      <div className="flex flex-col   md:flex-row-reverse md:items-center overflow-hidden">
        {/* video */}

        <div className="md:w-full">
          <video
            ref={videoRefs[0]}
            width="100%"
            height="100%"
            poster="https://dellace.s3.ap-south-1.amazonaws.com/vlcsnap-2023-11-28-01h51m57s518.png"
            controls
            muted
          >
            <source
              src="https://dellace.s3.ap-south-1.amazonaws.com/Ads+Landscape-+1920-1080.m4v"
              type="video/mp4"
            />
          </video>
        </div>

        {/* text */}
        <Slide direction={"up"}>
          <div className="md:w-3/4 md:px-8 p-4 tracking-wide text-zinc-800">
            <h1 className="text-3xl md:text-4xl font-bold md:py-8 py-4 ">
              LLoyd Law College
            </h1>
            <h2 className="text-2xl md:text-3xl mb-4  ">Admission reels</h2>
            <p className="text-justify">
              Created a series of videos for LLOYD Law College admission
              campaign including Reels, Display Ads & bumpers.
            </p>
          </div>
        </Slide>
      </div>

      {/* 2 */}
      <div className="flex flex-col   md:flex-row  md:items-center overflow-hidden">
        {/* video */}

        {/* <div className="md:w-full ">
          <video
            ref={videoRefs[1]}
            width="100%"
            height="100%"
            controls
            poster="https://dellace.s3.ap-south-1.amazonaws.com/vlcsnap-2023-11-28-01h51m43s382.png"
            muted
          >
            <source
              src="https://dellace.s3.ap-south-1.amazonaws.com/4.mp4"
              type="video/mp4"
            />
          </video>
        </div> */}

        {/* text */}
        {/* <Slide direction={"up"}>
          <div className="p-4 md:w-3/4 md:px-8 tracking-wide text-zinc-800">
            <h1 className="text-3xl md:text-4xl  font-bold md:py-8 py-4 ">
              Cryptocurrency
            </h1>
            <h2 className="text-2xl  md:text-3xl mb-4  ">Blockchain</h2>
            <p className="text-justify">
              Explainer video that describe the difference between regular fiat
              currency & crypto currency.
            </p>
          </div>
        </Slide> */}
      </div>

      {/* 4 */}
      <div className="flex flex-col   md:flex-row  md:items-center overflow-hidden">
        {/* video */}
        <div className="md:w-full">
          <video
            ref={videoRefs[3]}
            width="100%"
            height="100%"
            poster="https://dellace.s3.ap-south-1.amazonaws.com/vlcsnap-2023-11-28-00h51m58s786.png"
            controls
            muted
          >
            <source
              src="https://dellace.s3.ap-south-1.amazonaws.com/3.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        {/* text */}
        <Slide direction={"up"}>
          <div className="p-4 md:w-3/4 md:px-8 tracking-wide text-zinc-800">
            <h1 className="text-3xl md:text-4xl font-bold md:py-8 py-4">
              Teri Film
            </h1>
            <h2 className="text-2xl md:text-3xl mb-4  ">
              Use Public Transport
            </h2>
            <p className="text-justify">
              A film crafted for agency N-Dimension, With a motive to spread
              awareness to use public transport
            </p>
          </div>
        </Slide>
      </div>

      {/* 3 */}
      <div className="flex flex-col   md:flex-row-reverse md:items-center overflow-hidden">
        {/* video */}
        <div className="md:w-full">
          <video
            ref={videoRefs[2]}
            width="100%"
            height="100%"
            poster="https://dellace.s3.ap-south-1.amazonaws.com/vlcsnap-2023-11-28-02h00m45s711.png"
            controls
            muted
          >
            <source
              src="https://dellace.s3.ap-south-1.amazonaws.com/2.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        {/* text */}
        <Slide direction={"up"}>
          <div className="p-4  md:w-3/4 md:px-8 tracking-wide text-zinc-800">
            <h1 className="text-3xl md:text-4xl whitespace-nowrap font-bold md:py-8 py-4">
              Gaming Industry
            </h1>
            <h2 className="text-2xl md:text-3xl mb-4  ">Market & Statistic</h2>
            <p className="text-justify ">
              An informational video on gaming industry for a confidential
              client based in UK.
            </p>
          </div>
        </Slide>
      </div>
    </div>
  );
}

export default VideoContainer;
