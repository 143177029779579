import React from "react";
import { FaWhatsapp, FaEnvelope } from "react-icons/fa";

function Buttons() {
  return (
    <div className="fixed z-50 text-green-500 right-0 bottom-10">
      <div className="flex items-center justify-center rounded-full bg-white mr-10">
        <a
          className="whitespace-nowrap text-right text-5xl  font-bold p-2 hover:underline"
          href="https://wa.me/919911302024?text=Hello"
        >
          <FaWhatsapp className="inline" />
        </a>
      </div>
    </div>
  );
}

export default Buttons;
