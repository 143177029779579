import React from "react";
import { Link, useNavigate } from "react-router-dom";

const BlogCard = ({ img, title, text, url }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(`/blog/${url}`)}
      className="max-w-sm mx-auto mb-10 hover:shadow-sm bg-white shadow-xl overflow-hidden rounded-lg p-3"
    >
      <div className="h-1/2 overflow-hidden rounded-lg">
        <img
          className="w-full h-[90%]  object-cover"
          src={img}
          // alt={}
        />
      </div>
      <div className="h-1/2 flex flex-col justify-between">
        <div className="">
          <h1 className="text-xl text-ellipsis  mb-6 font-bold text-gray-800">
            {title}
          </h1>
          <h1 className="text-ellipsis  text-justify text-gray-800">{text}</h1>
        </div>
        <div className="text-blue-500 hover:underline">Read more</div>
      </div>
    </div>
  );
};

export default BlogCard;
